<template>
	<div class="menuBox flex mt-20">
		<div class="boxSome flex ai-center mx-20" v-for="(item,index) in list" :key="item.url"
			:style="{ 'background-image':	'linear-gradient(to right, '+colorList[index][0]+' , '+colorList[index][1]+')', 'background-color': colorList[index][0] }">
			<div class="img">
				<img src="@assets/osImg/indexpage/companyCount1.png" v-if="item.title=='企业总数'" alt="">
				<img src="@assets/osImg/indexpage/fix1.png" v-if="item.title=='维修订单'" alt="">
				<img src="@assets/osImg/indexpage/order1.png" v-if="item.title=='电商订单'" alt="">
				<img src="@assets/osImg/indexpage/zu1.png" v-if="item.title=='租赁订单'" alt="">
			</div>
			<div class="fs-24">
				<div class="title">{{item.title}}</div>
				<div class="num">{{item.isPrice==1?"￥":""}}{{item.numb}}</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				colorList: [
					['#E97627', '#FD9C5B'],
					['#2484F1', '#4AABFF'],
					['#645AF1', '#885AFF'],
					['#FF6069', '#FF5097']
				],
				list: [{
						title: '企业总数',
						numb: 0,
						isPrice: 0
					},

					{
						title: '电商订单',
						numb: "",
						isPrice: 1
					},
					{
						title: '租赁订单',
						numb: "",
						isPrice: 1
					},
					{
						title: '维修订单',
						numb: "",
						isPrice: 1
					}
				]
			}
		},
		created: function() {
			this.getstat()
		},
		methods: {
			getstat() {
				this.$get('/base-api/statistic/plat-overview').then(data => {
					if (data.code != 1000) {
						this.$message.error(data.msg);
					}
					this.list[0].numb = data.data.entCount;
					this.list[1].numb = data.data.shopAmount;
					this.list[2].numb = data.data.rentAmount;
					this.list[3].numb = data.data.repairAmount;
				})
			}

		}

	}
</script>

<style lang='scss' scoped>
	.menuBox {
		.boxSome {
			width: 330px;
			height: 200px;
			border-radius: 10px;
			text-align: center;
			font-size: 24px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #FFFFFF;
		}

		.num {
			line-height: 64px;
			font-size: 24px;
			font-family: PingFangSC-Light, PingFang SC;
			font-weight: 300;
			color: #FFFFFF;
		}
	}
</style>
