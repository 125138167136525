<template>
	<div class="indexState my-50">
		<div class="title">设备状态</div>
		<div class="flex">

			<div class="left flex flex-1  jc-center">
				<div class="flex ai-center"><img src="../../../assets/osImg/icDanti.png" alt=""></div>
				<div class="flex jc-center flex-col mx-40">
					<div class="text">设备正常<span class="g ml-10">{{fromObj.unit_normal}}</span></div>
					<div class="text">设备异常<span class="r ml-10">{{fromObj.unit_unusual}}</span></div>
				</div>
			</div>
			<div class="left flex flex-1  jc-center">
				<div class="flex ai-center"><img src="../../../assets/osImg/icCedian.png" alt=""></div>
				<div class="flex jc-center flex-col mx-40">
					<div class="text">设备正常<span class="g ml-10">{{fromObj.meas_normal}}</span></div>
					<div class="text">设备异常<span class="r ml-10">{{fromObj.meas_unusual}}</span></div>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
	export default {
		name: "indexState",
		data() {
			return {
				fromObj: {}
			}
		},
		mounted() {
			this.$get("/backend-api/web/index/total", {}).then((res) => {
				this.fromObj = res.data
			});
		}
	};
</script>

<style lang="scss">

</style>
