<template>
	<div class="indexChat">
		<div class="title">报警统计</div>
		<div class="flex" v-loading="loading">
			<div class="left ml-20 pt-30">
				<div class="text">{{ title }}</div>
				<div class="text text2">{{ total }}</div>
				<div>
					<i v-if="this.stat == -1" class="el-icon-top r"></i>
					<i v-if="this.stat == 1" class="el-icon-top r"></i>
					<!-- <i v-if="this.stat == 0" class="el-icon-top darkColor"></i> -->
					<span class="r" v-if="this.stat == -1">{{ rate }}</span>
					<span class="g" v-if="this.stat == 1">{{ rate }}</span>
					<span class="h" v-if="this.stat == 0">{{ rate }}</span>
					<span class="text1">{{ foot }}</span>
				</div>
			</div>
			<div>
				<div class="flex jc-between mt-20 mr-10">
					<div class="flex ai-center ml-10">
						<span class="fs-16" v-if="this.type == 0">近一日预警统计</span>
						<span class="fs-16" v-if="this.type == 1">近一周预警统计</span>
						<span class="fs-16" v-if="this.type == 2">近一月预警统计</span>
					</div>

					<div class="flex ai-center hover">
						<div class="ml-20" :class="{ active: this.type === 0 }" @click="changType(0)">
							今日
						</div>
						<div class="ml-20" :class="{ active: this.type === 1 }" @click="changType(1)">
							本周
						</div>
						<div class="ml-20" :class="{ active: this.type === 2 }" @click="changType(2)">
							本月
						</div>
						<div class="ml-20">
							<el-date-picker v-model="timeValue" type="daterange" range-separator="-"
								start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" @change="changTime(3)">
							</el-date-picker>
						</div>
					</div>
				</div>
				<div class="right" ref="indexChat"></div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "indexChat",
		props: ["chatsData"],
		data() {
			return {
				title: "",
				timeValue: "",
				xData: [],
				yData: [],
				total: "",
				rate: "",
				stat: "",
				foot: "",
				type: "",
				zData: [],
				loading:true,
				
			};
		},
		watch: {
			chatsData(val) {
				this.title = val.title;
				this.total = val.total;
				this.rate = val.rate;
				this.stat = val.stat;
				this.foot = val.foot;
				this.type = val.type;
				this.zData = val.data;
				this.init();
			},
		},
		methods: {
			init() {
				this.loading=false;
				const indexChat = this.$echarts.init(this.$refs.indexChat);
				let option = {
					grid: {
						top: "10%",
						left: "7%",
						right: "5%",
						bottom: "15%",
						// containLabel: true,
					},
					xAxis: {
						name: '时间',
						type: "time",
						splitLine: {
							show:true,
							lineStyle: {
								color: "#dedede",
								type: 'dashed'
							}
						},
						axisLine: {
							show:true,
						},
					},
					yAxis: {
						name: '次数',
						type: "value",
						// minInterval: 1,
						splitLine: {
							show:true,
							lineStyle: {
								color: "#dedede",
								type: 'dashed',
							}
						},
						axisLine: {
							show:true,
						},
					},
					series: [{
						data: this.zData,
						symbolSize: 5,
						type: "line",
						itemStyle: {
							normal: {
								color:'#15B931', //折点颜色
								lineStyle: {
									width: 0.8, // 0.1的线条是非常细的了
									color: "#15B931",
								},
							},
						},
					}, ],
					dataZoom: [{
							show: false,
							realtime: true,

						},
						{
							type: "inside",
							realtime: true,
						},
					],
				};
				indexChat.setOption(option, true);
			},
			changTime(type) {
				let obj = {
					type: type,
					timeValue: this.timeValue,
				};
				this.type = type;
				this.$emit("changTime", obj);

			},
			changType(type) {
				this.type = type;
				this.timeValue = null;
				this.$emit("changType", type);
			},
		},
	};
</script>

<style lang="scss" scoped>
	.right {
		height: 280px;
		width: 1200px;
	}
</style>
