<template>
	<div>
		<div v-if="activeIndex == 'platform'">

			<div class="main">

				<menuBox></menuBox>
				<indexState></indexState>
				<indexChat :chatsData="chatsData" @changType="changType" @changTime="changTime"></indexChat>
			</div>
		</div>

		<router-view v-else />
	</div>
</template>

<script>
	import menuBox from "../component/menuBox";
	import indexState from "../component/indexState";
	import indexChat from "../component/indexChat";
	import store from "../../../store/index";
	import configApi from "../../../config/configApi";
	export default {
		components: {
			menuBox,
			indexState,
			indexChat,
		},
		data() {
			return {
				activeIndex: "",
				chatsData: null,
				timeFrome: {
					type: "0",
				},
			};
		},
		watch: {
			"$route.name": {
				handler(val) {
					this.activeIndex = val;
					if(val=='platform'){
						this.getChats();
					}
				},
				immediate: true,
			},
		},
		mounted() {
			// this.getChats();
		},
		methods: {
			// 获取首页图表
			changType(val) {
				this.timeFrome.type = String(val);
				this.getChats();
			},
			changTime(obj) {
				this.timeFrome = {
					type: obj.type,
					begin: obj.timeValue[0],
					end: obj.timeValue[1],
				};
				// this.getChats();
			},
			getChats() {
				this.$get("/backend-api/web/index/spec", this.timeFrome).then((res) => {
					this.chatsData = res.data;
				});
			}
		},
	};
</script>

<style lang='scss'>
	@import "../../../style/home.scss";

	.indexTopBox {
		width: 100%;
		background-color: #fff;

		.indexTop {
			width: 1420px;
			height: 60px;
			margin: 0 auto;
		}
	}

	.home {
		.hover {
			cursor: pointer;
		}

		.el-avatar>img {
			width: 100%;
		}

		background-color: #f5f5f5;
		height: 100vh;

		.main {
			width: 1420px;
			margin: 0 auto;
		}
	}

	.el-popover {
		.userInfo {
			height: 40px;
			line-height: 40px;
			border-bottom: 1px solid #f5f5f5;
		}

		.textInfo {
			height: 30px;
			line-height: 30px;
		}

		.info {
			color: #9b9b9b;
		}
	}
</style>
